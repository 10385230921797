.date-picker {
  width: 100%;
}

.rmdp-shadow {
  box-shadow: none;
}

.rmdp-day {
  height: 2.75rem !important;
  width: 2.75rem !important;

  & > span {
    border-radius: 0.75rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}

.rmdp-day.rmdp-today span {
  background-color: inherit;
  color: inherit;
}

.rmdp-week {
  overflow: hidden;
  border-radius: 0.75rem;
  margin-top: 0.25rem;
}

.rmdp-week:nth-child(1),
.rmdp-week:nth-child(2) {
  margin-top: 0rem;
}

.rmdp-range {
  box-shadow: none;
  background-color: rgba(236, 249, 249, 1);
  color: rgba(45, 55, 69, 1);
}

.rmdp-day-picker,
.rmdp-calendar,
.rmdp-header {
  padding: 0;
  margin: 0;
}

.rmdp-day.rmdp-range.start > .sd,
.rmdp-day.rmdp-range.end > .sd {
  background-color: #42c1bf;
  color: #ffffff;
}

.rmdp-week-day {
  color: #ced6e3;
  font-family: 'Geologica Cursive';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  height: 3rem;
}

.rmdp-day.rmdp-deactive {
  color: #ced6e3;
}

.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: #42c1bf;
  color: #fff;
}

.rmdp-header {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.rmdp-header-values {
  color: #2d3745;
}

.rmdp-header-values span {
  padding: 0 0 0 1px;
}

.rmdp-day-picker > div:nth-child(1) {
  margin-right: 1.25rem !important;
}

.rmdp-day-picker > div:nth-child(2) {
  margin-left: 1.25rem !important;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -1.25rem;
    width: 1px;
    height: 100%;
    background-color: #f5f7f9;
  }
}
