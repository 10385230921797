.ag-root-wrapper {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  --ag-foreground-color: rgb(126, 46, 132);
}

.ag-body-viewport .ag-row-animation .ag-layout-normal {
  width: calc(100% + 0.75rem) !important;
}

.ag-ltr .ag-body-vertical-scroll.ag-scrollbar-invisible {
  background-color: rgba(245, 247, 249, 1);
  width: 0.75rem !important;
  max-width: 0.75rem !important;
  min-width: 0.75rem !important;
}

.ag-body-vertical-scroll-viewport {
  width: 0.75rem !important;
  max-width: 0.75rem !important;
  min-width: 0.75rem !important;
}

.ag-body-horizontal-scroll.ag-scrollbar-invisible.ag-apple-scrollbar {
  height: 0.75rem !important;
  max-height: 0.75rem !important;
  min-height: 0.75rem !important;
  background-color: rgba(245, 247, 249, 1);
}

.ag-body-horizontal-scroll-viewport,
.ag-body-horizontal-scroll .ag-apple-scrollbar .ag-scrollbar-invisible {
  height: 0.75rem !important;
  max-height: 0.75rem !important;
  min-height: 0.75rem !important;
}

.ag-theme-alpine {
  --ag-row-hover-color: rgba(218, 224, 234, 0.36);
  --ag-border-color: rgba(230, 235, 241, 1);
  --ag-secondary-border-color: rgba(230, 235, 241, 1);
  --ag-header-background-color: rgba(245, 247, 249, 1);
  --ag-row-height: 2.5rem;
  --ag-header-height: 3rem;
  --ag-font-size: 0.75rem;
  --ag-odd-row-background-color: #fff;
  --ag-header-column-resize-handle-width: 0;
  --ag-range-selection-border-color: rgba(66, 193, 191, 1);
  --ag-font-family: 'Geologica Cursive';
  --ag-icon-image-asc: url('../assets/asc.svg');
  --ag-icon-image-desc: url('../assets/desc.svg');

  --ag-icon-font-display-asc: none;
  --ag-icon-font-display-desc: none;
  --ag-icon-font-display-filter: none;
  --ag-popup-shadow: none;
}

.ag-header {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.ag-cell {
  font-weight: 400;
}

.ag-header-cell {
  font-weight: 500;
}

.ag-header-cell-resize {
  width: 0.125rem;
  height: 1.25rem;
  top: unset;
  background-color: rgba(230, 235, 241, 1);
}

.ag-header-cell-resize::after {
  content: '';
}

.ag-filter,
.ag-filter-menu {
  border-radius: 1.25rem;
}

.ag-popup-positioned-under {
  overflow: visible;
  top: 0 !important;
}

.ag-filter > .ag-react-container {
  border-radius: 1.25rem;
  background: rgba(243, 245, 248, 0.84);
  border: 1.2px solid white;
  box-shadow: 0px 0px 8px 0px rgba(45, 55, 69, 0.2);

  padding: 0.5rem;
  width: 20rem;
  height: max-content;
  overflow: visible;
}

.ag-floating-filter-button {
  display: none;
}

.ag-sort-indicator-container {
  margin-left: auto;
  margin-right: 0.5rem;
}

.ag-icon-desc {
  &::after {
    filter: invert(65%) sepia(88%) saturate(284%) hue-rotate(130deg)
      brightness(85%) contrast(95%);
  }
}

.ag-icon-asc {
  &::after {
    filter: invert(65%) sepia(88%) saturate(284%) hue-rotate(130deg)
      brightness(85%) contrast(95%);
  }
}

.ag-menu {
  border: none;
  box-shadow: none;
}
