div[class^='GenericGroups-module_genericGroups'] > div:not(:first-child),
div[class^='GenericGroups-module_topGroupsContainer'] > div:not(:first-child),
div[class^='GenGroup-module_buttonContainer'] > fieldset:first-child,
button[title='Any halogen or hydrogen'] {
  display: none;
}

.Accordion-module_accordionDetailsWrapper__fnSnc {
  width: 324px !important;
}
