.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  z-index: 9999;
  background-color: #f5f7f7;
  border: 0.075rem solid #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 12px 0px rgba(45, 55, 69, 0.04),
    0px -4px 12px 0px rgba(45, 55, 69, 0.04),
    0px 0px 4px 0px rgba(45, 55, 69, 0.02);
  font-size: 0.75rem;
  line-height: 1rem;
  min-width: 10rem;
  padding: 0.25rem;
}
.context-menu-short {
  min-width: 2rem;
}
.context-menu-item-divider {
  width: 100%;
  border-top: 0.075rem solid #ffffff;
  border-radius: 0.25rem;
}

.context-menu .ScrollbarsCustom-TrackY {
  right: 0 !important;
}

.context-menu .context-menu_inner {
  position: absolute;
  left: calc(100% + 0.375rem);
  visibility: visible;
  top: -0.0625rem;
  min-width: 10rem;
  width: max-content;
  max-width: 15rem;
}

.context-menu-item {
  height: 2.25rem;
  width: 100%;
  padding: 0.625rem 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: start;
  align-items: center;
  color: #6e809d;
  column-gap: 1rem;
  &:hover {
    background-color: #dae0ea5c !important;
    cursor: pointer;
  }
}
.context-menu-item-content {
  font-size: 0.75rem;
  line-height: 1rem;

  flex-grow: 1;
}

.context-menu-item-content-count {
  color: #42c1bf;
  margin-left: 0.5rem;
}

.context-menu-item:hover {
  background-color: rgb(245, 247, 247);
  cursor: pointer;
}

.context-menu-item .arrow-icon {
  margin-left: auto;
}

.context-menu-item-disabled {
  color: #ced6e3;
  pointer-events: none;
}

.context-menu-item.context-menu-item-disabled .hotkey {
  color: #ced6e3;
}

.context-menu-item .hotkey {
  color: #a0a8b0;
  font-size: 0.75rem;
}

.context-menu-item:hover {
  background-color: #ecf9f9;
  color: #42c1bf;
  cursor: pointer;
}

.context-menu-item:hover .context-menu-item-icon {
  color: #42c1bf;
}

.context-menu-item.context-menu-item_active-true {
  background-color: #ecf9f9;
  color: #42c1bf;
  cursor: pointer;
}

.context-menu-item.context-menu-item_active-true .context-menu-item-icon {
  color: #42c1bf;
}

.context-menu-item.context-menu-item_with-inner {
  position: relative;
}

.context-menu-item.context-menu-item_near-bottom .context-menu_inner {
  top: auto;
  bottom: -0.0625rem;
}

.context-menu-item.context-menu-item_near-right .context-menu_inner {
  left: auto;
  right: calc(100% + 0.375rem);
}

.context-menu-item.context-menu-item_disabled-true {
  color: #ced6e3;
  cursor: default;
}

.context-menu-item.context-menu-item_disabled-true:hover {
  background-color: #0a0b0c;
  color: #ced6e3;
}

.context-menu-item.context-menu-item_bordered {
}

.context-menu-item.context-menu-item_filter-true {
  color: #1f212366;
  background-color: transparent;
  cursor: default;
}

.context-menu-item.context-menu-item_divided {
  border-bottom: 1px solid #f2f4f5;
}

.context-menu-item b {
  margin: 0 0.25rem;
}

.context-menu-item b:hover {
  background-color: #ebd4f7;
  color: #ffffff;
  cursor: pointer;
}

.context-menu-item b:hover .context-menu-item-icon {
  color: #ffffff;
}

.context-menu-item .toggle {
  margin-right: 0.625rem;
}

.context-menu-group-divider {
  border-bottom: 0.075rem solid #ffffff;
}
.context-menu_inner-row {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  padding: 1rem;
  flex-wrap: wrap;
}

.context-menu_inner-row .context-menu-item {
  width: 100%;
  background-color: #f5f7f7;
  border-radius: 0.125rem;
  padding: 0.375rem;
}

.context-menu_inner-row .context-menu-item:hover {
  background-color: #ecf9f9;
}

.context-menu-item_disabled {
  color: #ced6e3;
  pointer-events: none;
}

.context-menu-item_disabled .hotkey {
  color: #ced6e3;
}

.context-menu-item_active-true {
  background-color: #bfdeff;
  color: #ffffff;
  cursor: pointer;
}

.context-menu-item_active-true .context-menu-item-icon {
  color: #ffffff;
}

.context-menu-item_with-inner {
  position: relative;
}

.context-menu-item_near-bottom .context-menu_inner {
  top: auto;
  bottom: -0.0625rem;
}

.context-menu-item_near-right .context-menu_inner {
  left: auto;
  right: calc(100% + 0.375rem);
}

.context-menu-item_disabled-true {
  color: #d0d3d8;
  cursor: default;
}

.context-menu-item_disabled-true:hover {
  background-color: #f2f4f5;
  color: #ced6e3;
}

.context-menu-item_filter-true {
  color: #34c759;
  background-color: transparent;
  cursor: default;
}

.context-menu-item_divided {
  border-bottom: 1px solid #f2f4f5;
}
