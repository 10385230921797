.feedback-error-icon {
  color: #f04438 !important;
}

.feedback-item_error-false,
.feedback-item-input_error-false > .custom-input-wrapper {
  border: 0.0625rem solid #ffff !important;
  border-radius: 0.75rem;
}

.feedback-item_error-true,
.feedback-item-input_error-true > .custom-input-wrapper {
  animation: shake 1s ease-in-out;
  border: 0.0625rem solid #f04438 !important;
  border-radius: 0.75rem;
}

.feedback-checkbox_error-false {
  border: 0.0625rem solid #ffff !important;
  border-radius: 0.25rem;
  margin-bottom: auto;
}

.feedback-checkbox_error-true {
  animation: shake 1s ease-in-out;
  border: 0.0625rem solid #f04438 !important;
  border-radius: 0.25rem;
  margin-bottom: auto;
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-0.1rem);
  }

  40% {
    transform: translateY(0.1rem);
  }

  60% {
    transform: translateY(-0.1rem);
  }

  80% {
    transform: translateY(0.1rem);
  }

  100% {
    transform: translateY(0);
  }
}

.feedback-disabled-input {
  &:hover {
    cursor: not-allowed;
    svg {
      cursor: not-allowed !important;
    }
  }
}
