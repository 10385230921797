.toggle {
  @apply self-center;
}

.toggle.toggle_is-checkbox-true > .toggle-label {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;

  &::before {
    content: none;
  }
}

.toggle.toggle_is-small-true > .toggle-label {
  width: 1rem;
  height: 1rem;
}

.toggle.toggle_is-checkbox-true > .toggle-input:checked + .toggle-label {
  background: #f5f7f9;
}

.toggle.toggle_is-checkbox-true
  > .toggle-input:checked
  + .toggle-label
  .toggle-label-slider {
  background: #42c1bf;
  width: 1rem;
  height: 1rem;
  box-shadow: 0px 0px 2px 0px rgba(45, 55, 69, 0.24);
  border-radius: 0.125rem;
}

.toggle.toggle_is-checkbox-true.toggle_is-small-true
  > .toggle-input:checked
  + .toggle-label
  .toggle-label-slider {
  width: 0.75rem;
  height: 0.75rem;
}

.toggle.toggle_is-checkbox-true .toggle-label-slider {
  width: 0.375rem;
  height: 0.375rem;
  background: transparent;
  border-radius: 1px;
  transform: none;
}

.toggle.toggle_is-radio-true > .toggle-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f7f9;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;

  &::before {
    content: none;
  }
}

.toggle.toggle_is-radio-true.toggle_is-small-true > .toggle-label {
  width: 1rem;
  height: 1rem;
}

.toggle.toggle_is-radio-true
  .toggle-input:checked
  + .toggle-label
  .toggle-label-slider {
  background: #42c1bf;
  width: 1rem;
  height: 1rem;
  box-shadow: 0px 0px 2px 0px rgba(45, 55, 69, 0.24);
}

.toggle.toggle_is-radio-true.toggle_is-small-true
  .toggle-input:checked
  + .toggle-label
  .toggle-label-slider {
  width: 0.75rem;
  height: 0.75rem;
}

.toggle.toggle_is-radio-true .toggle-label-slider {
  position: static;
  width: 0.375rem;
  height: 0.375rem;
  background: transparent;
  border-radius: 50% !important;
  transform: none;
}

.toggle-input {
  display: none;
}

.toggle-label {
  cursor: pointer;
  background: #f5f7f9 !important;
}

.toggle-input:checked + .toggle-label {
  background: #f5f7f9 !important;
}

.toggle-label-white {
  background: white !important;
}

.toggle-input:checked + .toggle-label-white {
  background: white !important;
}

.toggle.toggle_is-disabled-true > .toggle-label {
  cursor: not-allowed;
}
