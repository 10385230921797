.custom-scrollbar {
  min-height: 0;
  flex-grow: 1;
}
.scrollbar-hidden-true {
  display: none;
  opacity: 0;
}

.custom-scrollbar .ScrollbarsCustom-TrackY {
  width: 0.25rem !important;
  border-radius: 0;
  background-color: transparent !important;
}
.custom-scrollbar .ScrollbarsCustom-TrackY.ScrollbarsCustom-TrackY:hover,
.custom-scrollbar .ScrollbarsCustom-TrackY.ScrollbarsCustom-TrackY:active {
  width: 1rem;
  transition: width 0.3s;
  will-change: width;
}
.custom-scrollbar .ScrollbarsCustom-TrackY.ScrollbarsCustom-TrackX:hover,
.custom-scrollbar .ScrollbarsCustom-TrackY.ScrollbarsCustom-TrackX:active {
  height: 1rem;
  transition: height 0.3s;
  will-change: height;
}
.custom-scrollbar .ScrollbarsCustom-TrackY .ScrollbarsCustom-Thumb {
  border-radius: 0;
  background-color: rgba(203, 206, 209, 0.6) !important;
  margin: auto;
  border-width: 0.1875rem;
  border-color: transparent;
}

.custom-scrollbar .ScrollbarsCustom-TrackX .ScrollbarsCustom-Thumb {
  height: 0.25rem !important;
  background-color: rgba(203, 206, 209, 0.6) !important;
}
.custom-scrollbar .ScrollbarsCustom-TrackY .ScrollbarsCustom-Thumb:before {
  border-radius: 0;
  background-color: red !important;
}
.custom-scrollbar .ScrollbarsCustom-TrackX {
  height: 0.375rem !important;
  background: transparent !important;
  border-radius: 0;

  background-color: transparent;
}
.custom-scrollbar .ScrollbarsCustom-TrackX.ScrollbarsCustom-TrackY:hover,
.custom-scrollbar .ScrollbarsCustom-TrackX.ScrollbarsCustom-TrackY:active {
  width: 1rem !important;
  transition: width 0.3s;
  will-change: width;
}
.custom-scrollbar .ScrollbarsCustom-TrackX.ScrollbarsCustom-TrackX:hover,
.custom-scrollbar .ScrollbarsCustom-TrackX.ScrollbarsCustom-TrackX:active {
  height: 1rem;
  transition: height 0.3s;
  will-change: height;
}
.custom-scrollbar .ScrollbarsCustom-TrackY .ScrollbarsCustom-Thumb {
  border-radius: 0;
  background-color: transparent;
  border-width: 0.1875rem;
  border-color: transparent;
}
.custom-scrollbar .ScrollbarsCustom-TrackY .ScrollbarsCustom-Thumb:before {
  border-radius: 0;
  background-color: transparent !important;
}

.margin-4-right .ScrollbarsCustom-TrackY {
  margin-right: 0.25rem !important;
}

.margin-12-right .ScrollbarsCustom-TrackY {
  margin-right: 0.75rem !important;
}

.margin-12-right-logs .ScrollbarsCustom-TrackY {
  margin-right: 0.75rem;
}

.margin-4-bottom .ScrollbarsCustom-TrackX {
  margin-bottom: 0.25rem;
}

.margin--12-bottom .ScrollbarsCustom-TrackX {
  margin-bottom: -0.75rem;
}

.margin--4-right .ScrollbarsCustom-TrackY {
  margin-right: -0.25rem;
}

.margin--8-right .ScrollbarsCustom-TrackY {
  margin-right: -0.5rem;
}
.margin--12-right .ScrollbarsCustom-TrackY {
  margin-right: -0.75rem;
}

.margin--0-right .ScrollbarsCustom-TrackY {
  margin-right: 0;
}

.scroller--20 .ScrollbarsCustom-Scroller {
  inset: 0px 1px 0px 0px !important;
}

.radius-12 {
  border-radius: 0.75rem;
}

.radius-12 .ScrollbarsCustom-Wrapper {
  border-radius: 0.75rem;
}

.radius-20 {
  border-radius: 1.25rem;
}

.radius-20 .ScrollbarsCustom-Wrapper {
  border-radius: 1.25rem;
}

.nmr-table-scroll {
  width: 18rem !important;
  height: inherit !important;
  max-height: 424px;
}

.custom-scrollbar.nmr-table-scroll .ScrollbarsCustom-Wrapper {
  border-radius: 0.75rem;

  inset: 0px 0px 0px 0px !important;
}

.custom-scrollbar .ScrollbarsCustom-Wrapper {
  inset: 0px 10px 0px 0px !important;
}

.flex-col-scrollbar {
  .ScrollbarsCustom-Content {
    display: flex;
    flex-direction: column;
  }
}

.table-values-scroll {
  .ScrollbarsCustom-Wrapper {
    border-radius: 0.75rem;
    width: 100%;
  }
}

.scrollbar-structures-compare {
  .ScrollbarsCustom-Wrapper {
    width: 100%;
  }
}

.scrollbar-structures-compare .ScrollbarsCustom-TrackX {
  background-color: #ffffff !important;
  height: 0.75rem !important;
  display: flex;
  align-items: center;
  border-radius: 0.75rem !important;
  padding: 0.125rem;
}

.scrollbar-structures-compare .ScrollbarsCustom-TrackX .ScrollbarsCustom-Thumb {
  height: 0.5rem !important;
  background-color: rgba(218, 224, 234, 0.56);
  border-radius: 0.625rem !important;
}

.sticky_true .ScrollbarsCustom-TrackX {
  background-color: #f5f7f9 !important;
  margin-bottom: 1rem !important;
}

.hidden-scroll-x {
  .ScrollbarsCustom-TrackX {
    display: none;
  }
}

.no-inset .ScrollbarsCustom-Wrapper {
  inset: 0 !important;
}

.custom-scrollbar.reaction-custom-scrollbar .ScrollbarsCustom-TrackX {
  height: 0.75rem !important;
  background-color: #f5f7f9 !important;
  border-radius: 0.75rem !important;
  padding: 0.125rem;
}

.custom-scrollbar.reaction-custom-scrollbar .ScrollbarsCustom-Thumb {
  height: 0.5rem !important;
  border-radius: 0.625rem !important;
  background-color: #dae0ea8f !important;
  background-image: url('../assets/three-dots-gray.svg') !important;
  background-size: 0.5rem 0.5rem !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.literature-dialog-scroll {
  inset: 0 !important;
}

.literature-dialog-scroll .ScrollbarsCustom-TrackY {
  margin-right: -0.75rem;
}
