@font-face {
  font-family: 'Geologica Cursive';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Geologica-Light.eot'); /* IE9 Compat Modes */
  src: local('Geologica-Light'), local('Geologica-Light'),
    url('../assets/fonts/Geologica-Light.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/Geologica-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/Geologica-Light.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Geologica-Light.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Geologica Cursive';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Geologica-Regular.eot'); /* IE9 Compat Modes */
  src: local('Geologica-Regular'), local('Geologica-Regular'),
    url('../assets/fonts/Geologica-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* Super Modern Browsers */ url('../assets/fonts/Geologica-Regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Geologica-Regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Geologica Cursive';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Geologica-Medium.eot'); /* IE9 Compat Modes */
  src: local('Geologica-Medium'), local('Geologica-Medium'),
    url('../assets/fonts/Geologica-Medium.eot?#iefix')
      format('embedded-opentype'),
    /* Super Modern Browsers */ url('../assets/fonts/Geologica-Medium.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Geologica-Medium.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Geologica Cursive';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Geologica-SemiBold.eot'); /* IE9 Compat Modes */
  src: local('Geologica-Semibold'), local('Geologica-Semibold'),
    url('../assets/fonts/Geologica-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    /* Super Modern Browsers */ url('../assets/fonts/Geologica-SemiBold.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Geologica-SemiBold.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
