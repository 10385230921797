.dropzone {
  position:relative;
  min-width: 1rem;
  min-height: 1rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  margin-bottom: 0.75rem;
  margin-left: 0.75rem;
  border-radius: 0.75rem;
}
.dropzone-blur {
  backdrop-filter: blur(20px);
  border: 1px dashed #42c1bf;
  border-radius: 0.75rem;
  z-index:1;
  &:before {
    content: '';
    backdrop-filter: blur(0.125rem);
    position: absolute;
    background: rgba(255, 255, 255, 0.72);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 0.75rem;
  }
}

.dropzone-input {
  display: none;
}
.documents-blur {
  backdrop-filter: blur(20px);
  border: 1px dashed #42c1bf;
  border-radius: 0.75rem;
  &:before {
    content: '';
    backdrop-filter: blur(0.125rem);
    position: absolute;
    background: rgba(255, 255, 255, 0.72);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
}

.pdf2smiles-container {
  display: flex;
  flex-direction: column;
}
