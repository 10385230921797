body {
  font-family: Geologica Cursive;
  overflow: hidden;
}

.loader {
  width: 72px;
  height: 76px;
  display: block;
  margin: 15px auto;
  position: relative;
  color: blue;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
}

.logo-left {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  content: url('../assets/logo/logo-left.svg');
  transform: scale(0.5) translate(40%, 0);
  animation: animloader 1.5s infinite ease-in-out;
}

.logo-right {
  position: absolute;
  right: 0;
  top: 24%;
  width: 2.5rem;
  height: 2.5rem;
  content: url('../assets/logo/logo-right.svg');
  transform: scale(0.5) translate(40%, 0);
  animation: animloader 1.5s infinite ease-in-out;
}

.logo-bottom {
  content: url('../assets/logo/logo-bottom.svg');
  transform: scale(0.5) translate(40%, 0);
  animation: animloader 1.5s infinite ease-in-out;
  position: absolute;
  bottom: 0;
  width: 2.5rem;
  height: 2.5rem;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animloader {
  50% {
    transform: scale(1) translate(0%, 0%);
  }
}

@-moz-document url-prefix() {
  .drawn-molecule-large {
    overflow: hidden;
  }
}

.katex-display {
  display: inline !important;
}

.katex-display > .katex {
  display: inline !important;
}

.katex-display > .katex > .katex-html {
  display: inline !important;
}

[class*='DefaultMultiTool-module_default'] {
  z-index: 9999 !important;
}
